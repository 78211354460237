<template>
  <card :is-loading="isLoading" :page-title="pageTitle" :page-icon="pageIcon">
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-text>
            <v-form ref="changePasswordForm" v-model="validLogin" lazy-validation v-on:submit.prevent>
              <v-row class="mt-5">
                <v-col>
                  <g-p-auto-complete
                      api-path="admins"
                      :outlined="true"
                      :dense="true"
                      :search-value="cPData.user"
                      @clearSearchResult="clearUserHandler"
                      @selectSearchResult="selectUserHandler"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                      outlined
                      dense
                      color="deep-purple accent-4"
                      hide-details="auto"
                      type="password"
                      :rules="[rules.required, rules.min]"
                      v-model="cPData.password"
                      label="New Password"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col>
                  <v-text-field
                      outlined
                      dense
                      color="deep-purple accent-4"
                      hide-details="auto"
                      type="password"
                      v-model="cPData.cPassword"
                      :rules="passwordUpdateCRules"
                      label="Confirm Password"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <base-outlined-button
                text="Change"
                color="primary"
                icon="mdi-content-save"
                :loading="isULoading"
                @onButtonClick="changePasswordHandler"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </card>
</template>

<script>
import Card from "../components/Card";
import BaseOutlinedButton from "../components/buttons/BaseOutlinedButton";
import Validation from "../components/mixins/Validation";
import GPAutoComplete from "../components/GPAutoComplete";
export default {
  name: "change-admin-password",
  mixins: [Validation],
  components: {
    GPAutoComplete,
    BaseOutlinedButton,
    Card
  },
  data() {
    return {
      pageTitle: 'Change Admin Password',
      pageIcon: 'mdi-lock',
      isLoading: false,
      isULoading: false,
      validLogin: true,
      cPData: {
        user: null,
        password: '',
        cPassword: '',
      }
    }
  },
  methods: {
    changePasswordHandler() {
       if (!this.$refs.changePasswordForm.validate()) return;
       this.isULoading = true;
       this.$store.dispatch('user/resetAdminPassword', {...this.cPData})
           .then((response) => {
             const { data } = response;
             this.setSnackBar({ text: data.message, type: "info" });
           })
           .catch((error) => {
             this.setSnackBar({ text: error.response.data.errors.message[0], type: "error" });
           })
           .finally(() => {
             this.isULoading = false;
             this.$refs.changePasswordForm.reset();
           });
    },
    selectUserHandler(selection) {
      if (!selection) return;
      this.cPData.user = selection;
    },
    clearUserHandler() {
      this.cPData.user = null;
    },
    setSnackBar({ text, type }) {
      this.$store.commit("SET_SNACKBAR", { text, type });
    }
  }
}
</script>

<style scoped>

</style>